/* Thin 100 */
@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-thin.woff2') format('woff2'),
         url('../fonts/sybillapro-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-thin-italic.woff2') format('woff2'),
         url('../fonts/sybillapro-thin-italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

/* Light 200*/
@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-light.woff2') format('woff2'),
         url('../fonts/sybillapro-light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-light-italic.woff2') format('woff2'),
         url('../fonts/sybillapro-light-italic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

/* Book 300*/
@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-book.woff2') format('woff2'),
         url('../fonts/sybillapro-book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-book-italic.woff2') format('woff2'),
         url('../fonts/sybillapro-book-italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

/* Regular 400 */
@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-regular.woff2') format('woff2'),
         url('../fonts/sybillapro-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-regular-italic.woff2') format('woff2'),
         url('../fonts/sybillapro-regular-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Medium 500*/
@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-medium.woff2') format('woff2'),
         url('../fonts/sybillapro-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-medium-italic.woff2') format('woff2'),
         url('../fonts/sybillapro-medium-italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* Bold 600 */
@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-bold.woff2') format('woff2'),
         url('../fonts/sybillapro-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-bold-italic.woff2') format('woff2'),
         url('../fonts/sybillapro-bold-italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

/* Heavy 900 */
@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-heavy.woff2') format('woff2'),
         url('../fonts/sybillapro-heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro';
    src: url('../fonts/sybillapro-heavy-italic.woff2') format('woff2'),
         url('../fonts/sybillapro-heavy-italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

/* -- Narrow -- */

/* Thin 100 */
@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-thin.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-thin-italic.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-thin-italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

/* Light 200*/
@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-light.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-light-italic.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-light-italic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

/* Book 300*/
@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-book.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-book-italic.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-book-italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

/* Regular 400 */
@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-regular.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-regular-italic.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-regular-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Medium 500*/
@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-medium.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-medium-italic.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-medium-italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* Bold 600 */
@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-bold.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-bold-italic.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-bold-italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

/* Heavy 900 */
@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-heavy.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Narrow';
    src: url('../fonts/sybillapronarrow-heavy-italic.woff2') format('woff2'),
         url('../fonts/sybillapronarrow-heavy-italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

/* -- Condensed -- */

/* Thin 100 */
@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-thin.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-thin-italic.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-thin-italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

/* Light 200*/
@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-light.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-light-italic.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-light-italic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

/* Book 300*/
@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-book.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-book-italic.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-book-italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

/* Regular 400 */
@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-regular.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-regular-italic.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-regular-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Medium 500*/
@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-medium.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-medium-italic.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-medium-italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* Bold 600 */
@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-bold.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-bold-italic.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-bold-italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

/* Heavy 900 */
@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-heavy.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Sybilla Pro Condensed';
    src: url('../fonts/sybillaprocondensed-heavy-italic.woff2') format('woff2'),
         url('../fonts/sybillaprocondensed-heavy-italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
